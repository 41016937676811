[
  {
    "number": 1,
    "signed": 2020,
    "species": "Maple, green branch",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Practical",
      "Tactile"
    ],
    "description": "First bowl finished of the projected 81. Something of a “shiner” this bowl highlights idiosyncrasies of yellowish gold maple. Hours of thought transmitted through this project. Rough interior, thin walls, offset pith lending a sense. Nary fur on the outside. Bowl # 1 is collectable."
  },
  {
    "number": 2,
    "signed": 2020,
    "species": "Maple, green branch",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Artistic",
      "Tactile"
    ],
    "description": "Right away one’s eyes are drawn to the chipped portion of the upper edge. I felt the colour of the branch was too good to pass up. Cracks in this bowl happened after turning and remain with the bowl today. This is part of an early collection model. Bowl 2 is genuine."
  },
  {
    "number": 3,
    "signed": 2021,
    "species": "Maple (kiln dried)",
    "origin": "4x4 blocks of maple donated to Ottawa Valley Wood turners. Apparently, cut-offs from a bat manufacture’s order. The deal was to take as many as you like but to return in the next month some number of woodworkers mallet to be branded and given in gift bags at companies open house. Fine lumber, kiln dried varying in length from 8 inches to 20 inches, maybe the odd one longer still.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Artistic",
      "Practical"
    ],
    "description": "An early take on bowl design using stable lumber that has less possibility of changing shape while drying. These blocks call for a steady hand and sharp chisel as sanding kiln dried material calls for a more robust abrasive taking longer. Kiln-dried material will also “fly apart” more than green wood turning. Besides design and shape the grain of this piece is quite spectacular. An angle cut was taken from the block to place opposing grains across the bowl from each other. Bowl 3 is experimental."
  },
  {
    "number": 4,
    "signed": 2021,
    "species": "Maple, green branch",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Practical",
      "Tactile"
    ],
    "description": "Another bowl with “partial pith “this smooth shape is included in the early start of the project considering each design option for repetition, simplicity, salability. The partial pith inclusion always indicates consideration for grain/pith feature being highlighted. . By adding the little “flip” to the outside rim I was able to hollow out a very comfortable “feel” to the wood. Has “tactile” beauty more than many likely due to the projecting rim."
  },
  {
    "number": 5,
    "signed": 2021,
    "species": "Maple (kiln dried)",
    "origin": "4x4 blocks of maple donated to Ottawa Valley Wood turners. Apparently, cut-offs from a bat manufacture’s order. The deal was to take as many as you like but to return in the next month some number of woodworkers mallet to be branded and given in gift bags at companies open house. Fine lumber, kiln dried varying in length from 8 inches to 20 inches, maybe the odd one longer still.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "Love this piece with a crack across grain of almost 1/3 bowl’s circumference. Not too imaginatively cut with grain running straight across. A change in colour is distinct in its placement with little reason to believe any better effect would come from keeping the piece all dark, for example."
  },
  {
    "number": 6,
    "signed": 2021,
    "species": "Maple, pallet material",
    "origin": "Having been stored on this pallet for two years bricks were eventually used to complete driveway. Through the process pallet material became available.",
    "finish": "Need to verify",
    "sense": [],
    "description": "Holding bowl upside down palm it with your dominant hand. Bringing calm leads one to understand tactile pleasure. Adults who grip this piece will have a  realization. What feel for a child’s or a larger person’s hand?"
  },
  {
    "number": 7,
    "signed": 2021,
    "species": "Maple, pallet material",
    "origin": "Having been stored on this pallet for two years bricks were eventually used to complete driveway. Through the process pallet material became available.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "An experimental bit this bowl has a “concave” bottom and thought of at the time, very thin walls. To process the needs and restraints of the project created variations were tried. Depth and practicality abound for this piece, and though one may wish for a set perhaps to serve coleslaw in, there is only one bowl with this number and signing date. Bowl 7 is design."
  },
  {
    "number": 8,
    "signed": 2021,
    "species": "Maple (kiln dried)",
    "origin": "4x4 blocks of maple donated to Ottawa Valley Wood turners. Apparently, cut-offs from a bat manufacture’s order. The deal was to take as many as you like but to return in the next month some number of woodworkers mallet to be branded and given in gift bags at companies open house. Fine lumber, kiln dried varying in length from 8 inches to 20 inches, maybe the odd one longer still.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "A sensual though not sensuous piece of soft well-played design with proper sanding throughout. A bowl like this Bowl 8 is life."
  },
  {
    "number": 9,
    "signed": 2021,
    "species": "Maple",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "Hopefully the only bowl to be removed and replaced after signature and number were added. Much of the bark is retained with forgivable pieces missing. The exterior contains a mixture of smooth to rough as in a missing step on this particular piece. Bowl 9 is unique."
  },
  {
    "number": 10,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "A very wonderful turning with bursting colour and intact pith. Sometimes I see nothing but the small encapsulated trust of colour and form. These pieces are meant to be “for eyes only” since they lack stamina due to thin “tapered” sides."
  },
  {
    "number": 11,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 12,
    "signed": 2021,
    "species": "Maple",
    "origin": "Neighbour across the Crescent while spring pruning their maple tree allowed me to gather up what I could. After two years of storage began to make use of all of it all.",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [],
    "description": "Another “duck billed” piece. Will always love the look/surreal this shape brings forward. Stability? Usability? Don’t even give it a thought. Just a showpiece willing to show off. The bottom encountered one of the earlier methods of bottom sanding. This fine and stable piece holds little tactile beauty but shines as part of a smart collection of small wood turnings."
  },
  {
    "number": 13,
    "signed": 2021,
    "species": "Maple",
    "origin": "Accross the crescent",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Practical",
      "Tactile"
    ],
    "description": "Bowl 13 is Elaborate."
  },
  {
    "number": 14,
    "signed": 2021,
    "species": "Maple",
    "origin": "Accross the crescent",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Practical",
      "Tactile"
    ],
    "description": "It can be soft fibre, lost lignum, organic matter or the non planar cut across the branch that brings this effect. Bowl 14 is original."
  },
  {
    "number": 15,
    "signed": 2021,
    "species": "Maple",
    "origin": "Accross the crescent",
    "finish": "Boiled Linseed Oil x 3 coats",
    "sense": [
      "Artistic",
      "Practical"
    ],
    "description": "Stunning colour stripe with correct proportion to act as art or as candy dish. Bowl 15 is tough."
  },
  {
    "number": 16,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [
      "Artistic",
      "Tactile"
    ],
    "description": "Rough sections on end grain inside and out. Too wet wood. Bowl 16 is terrible"
  },
  {
    "number": 17,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [
      "Artistic",
      "Tactile"
    ],
    "description": "Too small to be practical while featuring a distictive pith inclusion. Bowl 17 is melliferous."
  },
  {
    "number": 18,
    "signed": "August 19, 2021",
    "species": "Juniper Root",
    "origin": "Front yard near house shubs were ripped out. A perfect sepperator.",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [
      "Artistic",
      "Tactile"
    ],
    "description": "Most extrodinary grain patterns make this the perfect piece to look at."
  },
  {
    "number": 19,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 20,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 21,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 22,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 23,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 24,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 25,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Boiled Linseed Oil x 2 coats",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 26,
    "signed": "August 19, 2021",
    "species": "Plum",
    "origin": "45–50 year old tree in neighbour's backyard",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 27,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 28,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 29,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 30,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 31,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 32,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 33,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 34,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 35,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 36,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 37,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 38,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 39,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 40,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 41,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 42,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 43,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 44,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 45,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 46,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 47,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 48,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 49,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 50,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 51,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 52,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 53,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 54,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 55,
    "signed": 2022,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 80,
    "signed": "unknown",
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  },
  {
    "number": 81,
    "signed": 2021,
    "species": "Need to verify",
    "origin": "Need to verify",
    "finish": "Need to verify",
    "sense": [],
    "description": "Need to find"
  }
]